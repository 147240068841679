<template>
  <div class="s-know">
    <div class="s-know__container v2-container">
      <div class="s-know__top">
        <img
          id="s-know-arrow"
          loading="lazy"
          class="s-know__decor s-know__decor--arrow"
          src="/v2/school/knowledge/arrow-rounded.webp"
          alt="arrow"
        >

        <img
          id="s-know-zip"
          loading="lazy"
          class="s-know__decor s-know__decor--zip"
          src="/v2/school/knowledge/zip.webp"
          alt="zip"
        >

        <h2 class="s-know__title title-primary">
          ...и много
          <span class="s-know__violet">
            уникального
          </span>
        </h2>

        <p class="s-know__descr text-secondary">
          Помимо общеобразовательной программы в&nbsp;онлайн-школе
          «Синергия», каждый ученик получает&nbsp;набор навыков для&nbsp;жизни
        </p>
      </div>

      <div class="s-know__main">
        <div class="s-know__left">
          <h3 class="s-know__subtitle title-secondary">
            Синергия&nbsp;— знания для&nbsp;карьеры
          </h3>

          <p class="s-know__text text-main">
            Построить успешную карьеру намного проще, когда осознанно
            подходишь к&nbsp;выбору будущей профессии. Мы знакомим учеников с&nbsp;большим
            разнообразием профессий через взаимодействие с&nbsp;факультетами университета:
            профильные мастер‑классы от экспертов факультетов, менторинг и&nbsp;многое другое
          </p>
        </div>

        <div class="s-know__rigth">
          <div class="s-know__swiper swiper">
            <img
              loading="lazy"
              class="swiper__circle"
              :style="{transform: 'rotate(' + circleDeg + 'deg)'}"
              src="/v2/school/knowledge/circle.svg"
              alt="circle"
            >

            <swiper
              ref="swiper"
              :options="swiperOption"
              @slideNextTransitionStart="circleDeg += 180"
              @slidePrevTransitionStart="circleDeg += -180"
            >
              <swiper-slide
                v-for="(item, index) in sliderList"
                :key="index"
                class="swiper__card"
              >
                <div class="swiper__inner">
                  <h4
                    class="swiper__title"
                    :class="{
                      'swiper__title--1' : item.id === 1,
                      'swiper__title--2' : item.id === 2,
                      'swiper__title--3' : item.id === 3,
                    }"
                    v-html="item.title"
                  />

                  <img
                    loading="lazy"
                    class="swiper__img"
                    :src="require(`~/static/v2/school/knowledge/slide-${index + 1}.webp`)"
                    alt="school"
                  >

                  <p class="swiper__descr">
                    {{ item.descr }}
                  </p>
                </div>
              </swiper-slide>
            </swiper>

            <div class="swiper__controls">
              <button
                class="swiper__btn swiper__btn--prev"
                @click="prev"
              >
                <img
                  loading="lazy"
                  class="swiper__arrow"
                  src="/v2/school/knowledge/arrow.svg"
                  alt="arrow"
                >
              </button>

              <div class="swiper__pagination" />

              <button
                class="swiper__btn swiper__btn--next"
                @click="next"
              >
                <img
                  loading="lazy"
                  class="swiper__arrow"
                  src="/v2/school/knowledge/arrow.svg"
                  alt="arrow"
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'SchoolKnowledge',
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      circleDeg: 0,

      swiperOption: {
        slidesPerView: '1',
        spaceBetween: 8,
        loop: true,
        speed: 1000,
        autoplay: {
          enabled: true,
          delay: 2000,
        },

        pagination: {
          el: '.swiper__pagination',
          clickable: true,
        },
      },

      sliderList: [
        {
          id: 1,
          title: 'Факультет робототехники <span>и беспилотных технологий</span>',
          descr:
            'Мастер-класс «Знакомство с\u00A0миром робототехники и\u00A0беспилотных технологий',
        },
        {
          id: 2,
          title: 'Факультет информационных технологий',
          descr: 'Защита проектов на форуме «Территория смыслов»',
        },
        {
          id: 3,
          title: 'Юридический факультет',
          descr: 'Мастер-класс по криминалистике',
        },
      ],
    };
  },
  methods: {
    next() {
      this.$refs.swiper.$swiper.slideNext();
    },

    prev() {
      this.$refs.swiper.$swiper.slidePrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.s-know {
  padding-top: 130px;

  overflow: hidden;

  @include media-down(tablet) {
    padding-top: 40px;
  }

  &__container {
    max-width: 1272px;
  }

  &__top {
    position: relative;

    margin-bottom: 220px;

    @media only screen and (max-width: $school-desktop) {
      margin-bottom: 50px;
    }

    @include media-down(laptop) {
      margin-bottom: 20px;
    }
  }

  &__decor {
    position: absolute;

    pointer-events: none;

    &--zip {
      top: -130px;
      left: 30px;

      width: 108px;

      @include media-down(tablet) {
        top: -40px;
        left: -28px;

        width: 60px;
      }

      @include media-down(mobile) {
        left: -8px;
      }
    }

    &--arrow {
      bottom: -110px;
      right: -30px;

      width: 143px;

      @include media-down(laptop) {
        bottom: -45px;
        right: -39px;

        width: 90px;

        transform: rotate(15deg);
      }

      @include media-down(tablet) {
        right: -20px;

        width: 60px;

        transform: rotate(27deg);
      }
    }
  }

  &__title {
    position: relative;

    margin-bottom: 20px;

    letter-spacing: -1.3px;
    text-align: center;

    @include media-down(tablet) {
      margin: 0 auto 10px;

      width: 270px;

      font-size: 30px;
    }
  }

  &__violet {
    position: relative;
    z-index: 1;

    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: -8px;

      display: block;

      width: 103%;
      height: 51px;

      background: $violet;

      @include media-down(tablet) {
        top: 0;
        left: -2px;

        width: 102%;
        height: 24px;
      }
    }
  }

  &__descr {
    position: relative;

    max-width: 800px;

    margin: 0 auto;

    text-align: center;

    @include media-down(laptop) {
      max-width: 520px;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__left {
    padding-top: 100px;

    width: 540px;

    @media only screen and (max-width: $school-desktop) {
      max-width: 550px;
      width: 100%;
    }

    @include media-down(tablet) {
      padding-top: 60px;
    }
  }

  &__subtitle {
    margin-bottom: 20px;

    letter-spacing: -1.35px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      font-size: 30px;
      line-height: 30px;
      text-transform: none;
      letter-spacing: -0.6px;
    }
  }

  &__text {
    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      font-weight: 600;
    }
  }
}

.swiper {
  position: relative;

  padding-top: 60px;

  width: 570px;

  overflow: hidden;

  @include media-down(tablet) {
    max-width: 570px;
    width: calc(100vw - 16px);
  }

  @include media-down(mobile) {
    width: 344px;
  }

  &__circle {
    position: absolute;
    top: 4px;
    left: 50%;

    width: 146px;
    height: 146px;

    transform: translateX(-50%);

    transition: 600ms ease-in-out;

    @include media-down(tablet) {
      top: 25px;
      left: 66px;

      width: 82px;
      height: 82px;
    }
  }

  &__inner {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 570px;
    height: 383px;

    border-radius: 20px;
    border: 2px solid $black;

    overflow: hidden;

    @include media-down(tablet) {
      max-width: 570px;
      width: calc(100vw - 16px);
    }

    @include media-down(mobile) {
      width: 344px;
      height: 231px;
    }
  }

  &__title {
    position: relative;
    z-index: 1;

    margin-bottom: auto;
    padding: 9px 15px 12px;

    height: 44px;

    border-bottom: 2px solid $black;

    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    color: $black;

    @include media-down(tablet) {
      padding: 5px 15px 12px 15px;

      height: 36px;

      font-size: 16px;
      text-align: left;
    }

    &--1 {
      background: #61DDBC;
    }

    &--2 {
      background: #9FE3FF;
    }

    &--3 {
      background: #FBD277;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 570px;
    height: 383px;

    object-fit: cover;

    @include media-down(mobile) {
      width: 344px;
      height: 231px;
    }
  }

  &__descr {
    position: relative;

    padding: 20px;

    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    color: $white-color;

    @include media-down(tablet) {
      padding: 16px;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    height: 110px;

    @include media-down(tablet) {
      gap: 20px;

      height: 80px;
    }
  }

  &__btn {
    margin: 0;
    padding: 0;

    width: 60px;
    height: 60px;

    border: none;
    border-radius: 50%;

    position: relative;
    cursor: pointer;

    @include media-down(tablet) {
      width: 44px;
      height: 44px;
    }

    &--prev {
      transform: rotate(180deg);

      filter: drop-shadow(-2px -2px 0 $black);
    }

    &--next {
      filter: drop-shadow(2px 2px 0 $black);
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;
  }

  &__pagination {
    display: flex;
    gap: 7px;

    width: fit-content;

    ::v-deep .swiper-pagination-bullet {
      display: block;

      width: 10px;
      height: 10px;

      background: $white-color;

      border: 2px solid $black;

      opacity: 1;

      &--active {
        background: #ff5151;
      }
    }

    ::v-deep .swiper-pagination-bullet-active {
      background: #ff5151;
    }
  }
}
</style>

<style lang="scss">
.swiper {
  &__title {
    & {
      span {
        @include media-down(mobile) {
          display: none;
        }
      }
    }
  }
}
</style>
