var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-know"},[_c('div',{staticClass:"s-know__container v2-container"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"s-know__main"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"s-know__rigth"},[_c('div',{staticClass:"s-know__swiper swiper"},[_c('img',{staticClass:"swiper__circle",style:({transform: 'rotate(' + _vm.circleDeg + 'deg)'}),attrs:{"loading":"lazy","src":"/v2/school/knowledge/circle.svg","alt":"circle"}}),_vm._v(" "),_c('swiper',{ref:"swiper",attrs:{"options":_vm.swiperOption},on:{"slideNextTransitionStart":function($event){_vm.circleDeg += 180},"slidePrevTransitionStart":function($event){_vm.circleDeg += -180}}},_vm._l((_vm.sliderList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"swiper__card"},[_c('div',{staticClass:"swiper__inner"},[_c('h4',{staticClass:"swiper__title",class:{
                    'swiper__title--1' : item.id === 1,
                    'swiper__title--2' : item.id === 2,
                    'swiper__title--3' : item.id === 3,
                  },domProps:{"innerHTML":_vm._s(item.title)}}),_vm._v(" "),_c('img',{staticClass:"swiper__img",attrs:{"loading":"lazy","src":require(`~/static/v2/school/knowledge/slide-${index + 1}.webp`),"alt":"school"}}),_vm._v(" "),_c('p',{staticClass:"swiper__descr"},[_vm._v("\n                  "+_vm._s(item.descr)+"\n                ")])])])}),1),_vm._v(" "),_c('div',{staticClass:"swiper__controls"},[_c('button',{staticClass:"swiper__btn swiper__btn--prev",on:{"click":_vm.prev}},[_c('img',{staticClass:"swiper__arrow",attrs:{"loading":"lazy","src":"/v2/school/knowledge/arrow.svg","alt":"arrow"}})]),_vm._v(" "),_c('div',{staticClass:"swiper__pagination"}),_vm._v(" "),_c('button',{staticClass:"swiper__btn swiper__btn--next",on:{"click":_vm.next}},[_c('img',{staticClass:"swiper__arrow",attrs:{"loading":"lazy","src":"/v2/school/knowledge/arrow.svg","alt":"arrow"}})])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-know__top"},[_c('img',{staticClass:"s-know__decor s-know__decor--arrow",attrs:{"id":"s-know-arrow","loading":"lazy","src":"/v2/school/knowledge/arrow-rounded.webp","alt":"arrow"}}),_vm._v(" "),_c('img',{staticClass:"s-know__decor s-know__decor--zip",attrs:{"id":"s-know-zip","loading":"lazy","src":"/v2/school/knowledge/zip.webp","alt":"zip"}}),_vm._v(" "),_c('h2',{staticClass:"s-know__title title-primary"},[_vm._v("\n        ...и много\n        "),_c('span',{staticClass:"s-know__violet"},[_vm._v("\n          уникального\n        ")])]),_vm._v(" "),_c('p',{staticClass:"s-know__descr text-secondary"},[_vm._v("\n        Помимо общеобразовательной программы в онлайн-школе\n        «Синергия», каждый ученик получает набор навыков для жизни\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-know__left"},[_c('h3',{staticClass:"s-know__subtitle title-secondary"},[_vm._v("\n          Синергия — знания для карьеры\n        ")]),_vm._v(" "),_c('p',{staticClass:"s-know__text text-main"},[_vm._v("\n          Построить успешную карьеру намного проще, когда осознанно\n          подходишь к выбору будущей профессии. Мы знакомим учеников с большим\n          разнообразием профессий через взаимодействие с факультетами университета:\n          профильные мастер‑классы от экспертов факультетов, менторинг и многое другое\n        ")])])
}]

export { render, staticRenderFns }